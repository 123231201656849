import { useEffect, useState } from "react";
import Skeleton from "../templates/skeleton";

export default function Settings(props) {

    const [created, setCreated] = useState(null);

    useEffect(() => {
        if (props.user) {
            setCreated(formatDate(props.user.created));
        }
    }, [props.user])


    const formatDate = (date) => {
        date = new Date(date);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    }


    return (
        <div className="mt-5 h-screen md:h-auto md:mt-0 md:ml-5 w-full border overflow-hidden border-stone-300 dark:border-stone-700 rounded-lg p-4">

            <span className="font-ibm font-medium text-xl text-stone-700 dark:text-stone-300">{props.option ? props.option : ""}</span>

            {/* <div className="bg-stone-900 rounded-lg w-full h-full flex items-center justify-center mb-4">

            


               

            </div> */}

            <div className="flex flex-col w-full mt-4 space-y-2">

                <div className="flex items-center space-x-2">

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
                    </svg>

                    <Skeleton loading={props.loading} className='w-40 h-5 object-cover rounded-full'>
                        <span className='h-6 text-base dark:text-white'><span className="text-stone-500">Email:</span>  {props.user ? props.user.email : ""}</span>
                    </Skeleton>
                </div>


                <div className="flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-stone-700 dark:text-stone-300 mr-2">
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                    </svg>

                    <Skeleton loading={props.loading} className='w-40 h-5 object-cover rounded-full'>
                        <span className='h-6 text-base dark:text-white'><span className="text-stone-500">Membro desde:</span>  {created}</span>
                    </Skeleton>
                </div>


                <div className="flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-stone-700 dark:text-stone-300 mr-2">
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                    </svg>

                    <Skeleton loading={props.loading} className='w-40 h-5 object-cover rounded-full'>
                        <span className='h-6 text-base dark:text-white'><span className="text-stone-500">Último login:</span>  {props.user ? formatDate(props.user.lastLogin) : ""}</span>
                    </Skeleton>
                </div>



            </div>

        </div>
    )
}