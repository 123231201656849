


export default function Modal(props) {

    // tailwind 
    return (
        <div className="fixed z-20 inset-0 overflow-y-auto">
            <div className="items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center block">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-zinc-950 opacity-75"></div>
                </div>
                <span className="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
                <div className="w-full px-10 md:px-0 md:!w-1/2 xl:!w-1/3 inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle">
                    <div className="bg-white dark:bg-zinc-900 px-4 pt-5 p-8 pb-4 rounded-lg">
                        <div className="flex items-start dark:text-white px-4">
                            <div className="w-full flex justify-between items-center mt-0 text-left">
                                <h3 className="text-lg leading-6 font-medium" id="modal-headline">
                                    {props.title}
                                </h3>

                                <div className="cursor-pointer dark:text-white dark:hover:bg-zinc-950/50 p-2 rounded-md transition duration-150 ease-in-out" onClick={() => props.setModal(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>

                                </div>

                            </div>

                        </div>
                        <div className="mt-2 text-white">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}