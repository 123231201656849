
export const faqs = [
    {
        question: "Oque é o touratank?",
        answer: "É uma plataforma que permite que você compre e venda, produtos e tecnologias de forma simples e rápida."
    },
    {
        question: "Como funciona?",
        answer: "Você se cadastra, escolhe um produto e compra. Simples assim."
    },
    {
        question: "Quanto posso comprar?",
        answer: "A partir de R$ 1,00."
    },
    {
        question: "Eu posso comprar e vender?",
        answer: "Sim, você pode comprar e vender produtos."
    }
]


export const benefits = [
    {
        title: "Compras",
        description: "Você pode comprar, vender e trocar de qualquer lugar e a qualquer hora, basta ter um dispositivo com acesso a internet.",
        icon: "m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
    },
    {
        title: "Pontos touro",
        description: "A cada negociação você ganha pontos touro. Com eles você pode comprar produtos e serviços na plataforma.",
        icon: "M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    },

    {
        title: "Segurança",
        description: "Você pode comprar com segurança, pois todos os anuncios são analisados antes de serem publicados.",
        icon: "M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z",
    },
    {
        title: "Comunidade",
        description: "Faça parte de uma comunidade de pessoas que buscam comprar, vender e trocar produtos e serviços.",
        icon: "M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
    }
]




export const categories = [
    {
        "label": "Criptomoedas e NFT",
        "value": "criptomoedas_e_nft",
        "image": "https://th.bing.com/th/id/R.46afadddcc08be483a971581981f2b04?rik=5zZqzokQPvp5Ww&riu=http%3a%2f%2fstore-images.s-microsoft.com%2fimage%2fapps.17518.9007199266525906.711fe06b-31fe-48b3-85b2-9147c65d66ce.8e1c2c26-16e6-413b-926e-b785d38b915a&ehk=FJgCYuMHrIquhx9LbTCEvce5QbFloAQ8SsjBlH0T1ys%3d&risl=&pid=ImgRaw&r=0"
    },
    {
        "label": "Assinaturas e Premium",
        "value": "assinaturas_e_premium",
        "image": "https://vignette2.wikia.nocookie.net/logopedia/images/b/b2/NetflixIcon2016.jpg/revision/latest/scale-to-width-down/2000?cb=20160620223003"
    },
    {
        "label": "Emails",
        "value": "emails",
        "image": "https://th.bing.com/th/id/R.5325dd3e2e9e014060752a1dc40f7027?rik=anVZXWRbZbY6JA&pid=ImgRaw&r=0"
    },
    {
        "label": "Discord",
        "value": "discord",
        "image": "https://static.vecteezy.com/system/resources/previews/006/892/625/non_2x/discord-logo-icon-editorial-free-vector.jpg"
    },
    {
        "label": "Gift Cards",
        "value": "gift_cards",
        "image": "https://static.vecteezy.com/system/resources/previews/022/484/501/original/google-play-store-icon-logo-symbol-free-png.png"
    },
    {
        "label": "Redes Sociais",
        "value": "redes_sociais",
        "image": "https://th.bing.com/th/id/R.cc86a3353385063cc55b2675261f87d8?rik=yktDKaDmO08LXg&pid=ImgRaw&r=0"
    },
    {
        "label": "Serviços Digitais",
        "value": "servicos_digitais",
        "image": ""
    },
    {
        "label": "Softwares e Licenças",
        "value": "softwares_e_licencas",
        "image": "https://th.bing.com/th/id/R.5d11a8c34d6896da7660200637fccc76?rik=OsrU52SK1JpTQg&pid=ImgRaw&r=0"
    },
    {
        "label": "Apostas",
        "value": "apostas",
        "image": "https://th.bing.com/th/id/R.1766fe9dbd7d016d03bff30f8ffdd154?rik=1LD%2ftaV0C96w2g&pid=ImgRaw&r=0"
    },
    {
        "label": "eBooks",
        "value": "ebooks",
        "image": ""
    },
    {
        "label": "Doações",
        "value": "doacoes",
        "image": ""
    },
    {
        "label": "Outros",
        "value": "outros",
        "image": ""
    },
    {
        "label": "8 Ball Pool",
        "value": "8_ball_pool",
        "image": "/imgs/categories/8 Ball Pool.png"
    },
    {
        "label": "A3: Still Alive",
        "value": "a3:_still_alive",
        "image": "/imgs/categories/A3 Still Alive.png"
    },
    {
        "label": "Adventure Quest World",
        "value": "adventure_quest_world",
        "image": "/imgs/categories/Adventure Quest World.png"
    },
    {
        "label": "Aika",
        "value": "aika",
        "image": "/imgs/categories/Aika.png"
    },
    {
        "label": "Aion",
        "value": "aion",
        "image": "/imgs/categories/Aion.png"
    },
    {
        "label": "Albion Online",
        "value": "albion_online",
        "image": "/imgs/categories/Albion Online.png"
    },
    {
        "label": "Apex Legends",
        "value": "apex_legends",
        "image": "/imgs/categories/Apex Legends.png"
    },
    {
        "label": "Avakin Life",
        "value": "avakin_life",
        "image": "/imgs/categories/Avakin Life.png"
    },
    {
        "label": "Black Clover Mobile",
        "value": "black_clover_mobile",
        "image": "/imgs/categories/Black Clover Mobile.png"
    },
    {
        "label": "Black Desert",
        "value": "black_desert",
        "image": "/imgs/categories/Black Desert.png"
    },
    {
        "label": "Blade and Soul",
        "value": "blade_and_soul",
        "image": "/imgs/categories/Blade and Soul.png"
    },
    {
        "label": "Brawl Stars",
        "value": "brawl_stars",
        "image": "/imgs/categories/Brawl Stars.png"
    },
    {
        "label": "Brawlhalla",
        "value": "brawlhalla",
        "image": "/imgs/categories/Brawlhalla.png"
    },
    {
        "label": "Cabal Online",
        "value": "cabal_online",
        "image": "/imgs/categories/Cabal Online.png"
    },
    {
        "label": "Call of Duty",
        "value": "call_of_duty",
        "image": "/imgs/categories/Call of Duty.png"
    },
    {
        "label": "Clash of Clans",
        "value": "clash_of_clans",
        "image": "/imgs/categories/Clash of Clans.png"
    },
    {
        "label": "Clash Royale",
        "value": "clash_royale",
        "image": "/imgs/categories/Clash Royale.png"
    },
    {
        "label": "Coin Master",
        "value": "coin_master",
        "image": "/imgs/categories/Coin Master.png"
    },
    {
        "label": "Combat Arms",
        "value": "combat_arms",
        "image": "/imgs/categories/Combat Arms.png"
    },
    {
        "label": "Counter Strike",
        "value": "counter_strike",
        "image": "/imgs/categories/Counter Strike.png"
    },
    {
        "label": "Crossfire",
        "value": "crossfire",
        "image": "/imgs/categories/Crossfire.png"
    },
    {
        "label": "Dark and Darker",
        "value": "dark_and_darker",
        "image": "/imgs/categories/Dark and Darker.png"
    },
    {
        "label": "DDTank",
        "value": "ddtank",
        "image": "/imgs/categories/DDTank.png"
    },
    {
        "label": "Dead by Daylight",
        "value": "dead_by_daylight",
        "image": "/imgs/categories/Dead by Daylight.png"
    },
    {
        "label": "Diablo Immortal",
        "value": "diablo_immortal",
        "image": "/imgs/categories/Diablo Immortal.png"
    },
    {
        "label": "Diablo IV",
        "value": "diablo_iv",
        "image": "/imgs/categories/Diablo IV.png"
    },
    {
        "label": "Digimon Masters Online",
        "value": "digimon_masters_online",
        "image": "/imgs/categories/Digimon Masters Online.png"
    },
    {
        "label": "Dofus",
        "value": "dofus",
        "image": "/imgs/categories/Dofus.png"
    },
    {
        "label": "DOTA 2",
        "value": "dota_2",
        "image": "/imgs/categories/DOTA 2.png"
    },
    {
        "label": "Dragon Ball Legends",
        "value": "dragon_ball_legends",
        "image": "/imgs/categories/Dragon Ball Legends.png"
    },
    {
        "label": "Dragon City Mobile",
        "value": "dragon_city_mobile",
        "image": "/imgs/categories/Dragon City Mobile.png"
    },
    {
        "label": "Elden Ring",
        "value": "elden_ring",
        "image": "/imgs/categories/Elden Ring.png"
    },
    {
        "label": "Epic Games",
        "value": "epic_games",
        "image": "/imgs/categories/Epic Games.png"
    },
    {
        "label": "Epic Seven",
        "value": "epic_seven",
        "image": "/imgs/categories/Epic Seven.png"
    },
    {
        "label": "Escape from Tarkov",
        "value": "escape_from_tarkov",
        "image": "/imgs/categories/Escape from Tarkov.png"
    },
    {
        "label": "Farlight 84",
        "value": "farlight_84",
        "image": "/imgs/categories/Farlight 84.png"
    },
    {
        "label": "FIFA",
        "value": "fifa",
        "image": "/imgs/categories/FIFA.png"
    },
    {
        "label": "For Honor",
        "value": "for_honor",
        "image": "/imgs/categories/For Honor.png"
    },
    {
        "label": "Fortnite",
        "value": "fortnite",
        "image": "/imgs/categories/Fortnite.png"
    },
    {
        "label": "Forza Horizon",
        "value": "forza_horizon",
        "image": "/imgs/categories/Forza Horizon.png"
    },
    {
        "label": "Free Fire",
        "value": "free_fire",
        "image": "/imgs/categories/Free Fire.png"
    },
    {
        "label": "Genshin Impact",
        "value": "genshin_impact",
        "image": "/imgs/categories/Genshin Impact.png"
    },
    {
        "label": "GOG",
        "value": "gog",
        "image": "/imgs/categories/GOG.png"
    },
    {
        "label": "Grand Chase",
        "value": "grand_chase",
        "image": "/imgs/categories/Grand Chase.png"
    },
    {
        "label": "Grand Fantasia",
        "value": "grand_fantasia",
        "image": "/imgs/categories/Grand Fantasia.png"
    },
    {
        "label": "GTA",
        "value": "gta",
        "image": "/imgs/categories/GTA.png"
    },
    {
        "label": "Guild Wars 2",
        "value": "guild_wars_2",
        "image": "/imgs/categories/Guild Wars 2.png"
    },
    {
        "label": "Habbo",
        "value": "habbo",
        "image": "/imgs/categories/Habbo.png"
    },
    {
        "label": "Hay Day",
        "value": "hay_day",
        "image": "/imgs/categories/Hay Day.png"
    },
    {
        "label": "Heartwood Online",
        "value": "heartwood_online",
        "image": "/imgs/categories/Heartwood Online.png"
    },
    {
        "label": "Honkai Impact",
        "value": "honkai_impact",
        "image": "/imgs/categories/Honkai Impact.png"
    },
    {
        "label": "Honkai: Star Rail",
        "value": "honkai_star_rail",
        "image": "/imgs/categories/Honkai Star Rail.png"
    },
    {
        "label": "Honor of Kings",
        "value": "honor_of_kings",
        "image": "/imgs/categories/Honor of Kings.png"
    },
    {
        "label": "Icarus Online",
        "value": "icarus_online",
        "image": "/imgs/categories/Icarus Online.png"
    },
    {
        "label": "IMVU",
        "value": "imvu",
        "image": "/imgs/categories/IMVU.png"
    },
    {
        "label": "Kakele Online",
        "value": "kakele_online",
        "image": "/imgs/categories/Kakele Online.png"
    },
    {
        "label": "League of Legends",
        "value": "league_of_legends",
        "image": "/imgs/categories/League of Legends.png"
    },
    {
        "label": "League of Legends: Wild Rift",
        "value": "league_of_legends_wild_rift",
        "image": "/imgs/categories/League of Legends Wild Rift.png"
    },
    {
        "label": "Legend Online",
        "value": "legend_online",
        "image": "/imgs/categories/Legend Online.png"
    },
    {
        "label": "Legends of Runeterra",
        "value": "legends_of_runeterra",
        "image": "/imgs/categories/Legends of Runeterra.png"
    },
    {
        "label": "Lords Mobile",
        "value": "lords_mobile",
        "image": "/imgs/categories/Lords Mobile.png"
    },
    {
        "label": "Lost Ark",
        "value": "lost_ark",
        "image": "/imgs/categories/Lost Ark.png"
    },
    {
        "label": "Magic The Gathering",
        "value": "magic_the_gathering",
        "image": "/imgs/categories/Magic The Gathering.png"
    },
    {
        "label": "Metin 2",
        "value": "metin_2",
        "image": "/imgs/categories/Metin 2.png"
    },
    {
        "label": "Minecraft",
        "value": "minecraft",
        "image": "/imgs/categories/Minecraft.png"
    },
    {
        "label": "MIR4",
        "value": "mir4",
        "image": "/imgs/categories/MIR4.png"
    },
    {
        "label": "Mobile Legends",
        "value": "mobile_legends",
        "image": "/imgs/categories/Mobile Legends.png"
    },
    {
        "label": "MU Legend",
        "value": "mu_legend",
        "image": "/imgs/categories/MU Legend.png"
    },
    {
        "label": "MU Online",
        "value": "mu_online",
        "image": "/imgs/categories/MU Online.png"
    },
    {
        "label": "My Hero Academia",
        "value": "my_hero_academia",
        "image": "/imgs/categories/My Hero Academia.png"
    },
    {
        "label": "Naruto Online",
        "value": "naruto_online",
        "image": "/imgs/categories/Naruto Online.png"
    },
    {
        "label": "New World",
        "value": "new_world",
        "image": "/imgs/categories/New World.png"
    },
    {
        "label": "Ni No Kuni",
        "value": "ni_no_kuni",
        "image": "/imgs/categories/Ni No Kuni.png"
    },
    {
        "label": "Noahs Heart",
        "value": "noahs_heart",
        "image": "/imgs/categories/Noahs Heart.png"
    },
    {
        "label": "Origin",
        "value": "origin",
        "image": "/imgs/categories/Origin.png"
    },
    {
        "label": "Overwatch",
        "value": "overwatch",
        "image": "/imgs/categories/Overwatch.png"
    },
    {
        "label": "Palworld",
        "value": "palworld",
        "image": "/imgs/categories/Palworld.png"
    },
    {
        "label": "Path of Exile",
        "value": "path_of_exile",
        "image": "/imgs/categories/Path of Exile.png"
    },
    {
        "label": "Perfect World",
        "value": "perfect_world",
        "image": "/imgs/categories/Perfect World.png"
    },
    {
        "label": "PES",
        "value": "pes",
        "image": "/imgs/categories/PES.png"
    },
    {
        "label": "Playstation",
        "value": "playstation",
        "image": "/imgs/categories/Playstation.png"
    },
    {
        "label": "Point Blank",
        "value": "point_blank",
        "image": "/imgs/categories/Point Blank.png"
    },
    {
        "label": "Pokemon GO",
        "value": "pokemon_go",
        "image": "/imgs/categories/Pokemon GO.png"
    },
    {
        "label": "Pokemon Unite",
        "value": "pokemon_unite",
        "image": "/imgs/categories/Pokemon Unite.png"
    },
    {
        "label": "PokeXGames",
        "value": "pokexgames",
        "image": "/imgs/categories/PokeXGames.png"
    },
    {
        "label": "Priston Tale",
        "value": "priston_tale",
        "image": "/imgs/categories/Priston Tale.png"
    },
    {
        "label": "PUBG",
        "value": "pubg",
        "image": "/imgs/categories/PUBG.png"
    },
    {
        "label": "Ragnarok",
        "value": "ragnarok",
        "image": "/imgs/categories/Ragnarok.png"
    },
    {
        "label": "Rainbow Six",
        "value": "rainbow_six",
        "image": "/imgs/categories/Rainbow Six.png"
    },
    {
        "label": "Ravendawn",
        "value": "ravendawn",
        "image": "/imgs/categories/Ravendawn.png"
    },
    {
        "label": "Red Dead Online",
        "value": "red_dead_online",
        "image": "/imgs/categories/Red Dead Online.png"
    },
    {
        "label": "Roblox",
        "value": "roblox",
        "image": "/imgs/categories/Roblox.png"
    },
    {
        "label": "Rocket League",
        "value": "rocket_league",
        "image": "/imgs/categories/Rocket League.png"
    },
    {
        "label": "Rucoy Online",
        "value": "rucoy_online",
        "image": "/imgs/categories/Rucoy Online.png"
    },
    {
        "label": "Runescape",
        "value": "runescape",
        "image": "/imgs/categories/Runescape.png"
    },
    {
        "label": "Rush Royale",
        "value": "rush_royale",
        "image": "/imgs/categories/Rush Royale.png"
    },
    {
        "label": "Saint Seiya Awakening",
        "value": "saint_seiya_awakening",
        "image": "/imgs/categories/Saint Seiya Awakening.png"
    },
    {
        "label": "Seafight",
        "value": "seafight",
        "image": "/imgs/categories/Seafight.png"
    },
    {
        "label": "Seven Deadly Sins",
        "value": "seven_deadly_sins",
        "image": "/imgs/categories/Seven Deadly Sins.png"
    },
    {
        "label": "Smite",
        "value": "smite",
        "image": "/imgs/categories/Smite.png"
    },
    {
        "label": "Standoff 2",
        "value": "standoff_2",
        "image": "/imgs/categories/Standoff 2.png"
    },
    {
        "label": "Steam",
        "value": "steam",
        "image": "/imgs/categories/Steam.png"
    },
    {
        "label": "Stumble Guys",
        "value": "stumble_guys",
        "image": "/imgs/categories/Stumble Guys.png"
    },
    {
        "label": "Subway Surfers",
        "value": "subway_surfers",
        "image": "/imgs/categories/Subway Surfers.png"
    },
    {
        "label": "Summoners War",
        "value": "summoners_war",
        "image": "/imgs/categories/Summoners War.png"
    },
    {
        "label": "Tibia",
        "value": "tibia",
        "image": "/imgs/categories/Tibia.png"
    },
    {
        "label": "Tower of Fantasy",
        "value": "tower_of_fantasy",
        "image": "/imgs/categories/Tower of Fantasy.png"
    },
    {
        "label": "Transformice",
        "value": "transformice",
        "image": "/imgs/categories/Transformice.png"
    },
    {
        "label": "Ubisoft",
        "value": "ubisoft",
        "image": "/imgs/categories/Ubisoft.png"
    },
    {
        "label": "Valorant",
        "value": "valorant",
        "image": "/imgs/categories/Valorant.png"
    },
    {
        "label": "Wakfu",
        "value": "wakfu",
        "image": "/imgs/categories/Wakfu.png"
    },
    {
        "label": "Warface",
        "value": "warface",
        "image": "/imgs/categories/Warface.png"
    },
    {
        "label": "Warframe",
        "value": "warframe",
        "image": "/imgs/categories/Warframe.png"
    },
    {
        "label": "Warspear",
        "value": "warspear",
        "image": "/imgs/categories/Warspear.png"
    },
    {
        "label": "With Your Destiny",
        "value": "with_your_destiny",
        "image": "/imgs/categories/With Your Destiny.png"
    },
    {
        "label": "World of Tanks",
        "value": "world_of_tanks",
        "image": "/imgs/categories/World of Tanks.png"
    },
    {
        "label": "World of Warcraft",
        "value": "world_of_warcraft",
        "image": "/imgs/categories/World of Warcraft.png"
    },
    {
        "label": "Xbox",
        "value": "xbox",
        "image": "/imgs/categories/Xbox.png"
    },
    {
        "label": "Outros Jogos",
        "value": "outros_jogos",
        "image": "/imgs/categories/Outros Jogos.png"
    },
    {
        "label": "Sugerir Jogo",
        "value": "sugerir_jogo",
        "image": "/imgs/bull/icone.png"
    }
]


export const games = [
    { "label": "8 Ball Pool", "value": "8_ball_pool", "image": "/imgs/categories/8 Ball Pool.png" },
    { "label": "A3: Still Alive", "value": "a3_still_alive", "image": "/imgs/categories/A3 Still Alive.png" },
    { "label": "Adventure Quest World", "value": "adventure_quest_world", "image": "/imgs/categories/Adventure Quest World.png" },
    { "label": "Aika", "value": "aika", "image": "/imgs/categories/Aika.png" },
    { "label": "Aion", "value": "aion", "image": "/imgs/categories/Aion.png" },
    { "label": "Albion Online", "value": "albion_online", "image": "/imgs/categories/Albion Online.png" },
    { "label": "Apex Legends", "value": "apex_legends", "image": "/imgs/categories/Apex Legends.png" },
    { "label": "Avakin Life", "value": "avakin_life", "image": "/imgs/categories/Avakin Life.png" },
    { "label": "Black Clover Mobile", "value": "black_clover_mobile", "image": "/imgs/categories/Black Clover Mobile.png" },
    { "label": "Black Desert", "value": "black_desert", "image": "/imgs/categories/Black Desert.png" },
    { "label": "Blade and Soul", "value": "blade_and_soul", "image": "/imgs/categories/Blade and Soul.png" },
    { "label": "Brawl Stars", "value": "brawl_stars", "image": "/imgs/categories/Brawl Stars.png" },
    { "label": "Brawlhalla", "value": "brawlhalla", "image": "/imgs/categories/Brawlhalla.png" },
    { "label": "Cabal Online", "value": "cabal_online", "image": "/imgs/categories/Cabal Online.png" },
    { "label": "Call of Duty", "value": "call_of_duty", "image": "/imgs/categories/Call of Duty.png" },
    { "label": "Clash of Clans", "value": "clash_of_clans", "image": "/imgs/categories/Clash of Clans.png" },
    { "label": "Clash Royale", "value": "clash_royale", "image": "/imgs/categories/Clash Royale.png" },
    { "label": "Coin Master", "value": "coin_master", "image": "/imgs/categories/Coin Master.png" },
    { "label": "Combat Arms", "value": "combat_arms", "image": "/imgs/categories/Combat Arms.png" },
    { "label": "Counter Strike", "value": "counter_strike", "image": "/imgs/categories/Counter Strike.png" },
    { "label": "Crossfire", "value": "crossfire", "image": "/imgs/categories/Crossfire.png" },
    { "label": "Dark and Darker", "value": "dark_and_darker", "image": "/imgs/categories/Dark and Darker.png" },
    { "label": "DDTank", "value": "ddtank", "image": "/imgs/categories/DDTank.png" },
    { "label": "Dead by Daylight", "value": "dead_by_daylight", "image": "/imgs/categories/Dead by Daylight.png" },
    { "label": "Diablo Immortal", "value": "diablo_immortal", "image": "/imgs/categories/Diablo Immortal.png" },
    { "label": "Diablo IV", "value": "diablo_iv", "image": "/imgs/categories/Diablo IV.png" },
    { "label": "Digimon Masters Online", "value": "digimon_masters_online", "image": "/imgs/categories/Digimon Masters Online.png" },
    { "label": "Dofus", "value": "dofus", "image": "/imgs/categories/Dofus.png" },
    { "label": "DOTA 2", "value": "dota_2", "image": "/imgs/categories/DOTA 2.png" },
    { "label": "Dragon Ball Legends", "value": "dragon_ball_legends", "image": "/imgs/categories/Dragon Ball Legends.png" },
    { "label": "Dragon City Mobile", "value": "dragon_city_mobile", "image": "/imgs/categories/Dragon City Mobile.png" },
    { "label": "Elden Ring", "value": "elden_ring", "image": "/imgs/categories/Elden Ring.png" },
    { "label": "Epic Games", "value": "epic_games", "image": "/imgs/categories/Epic Games.png" },
    { "label": "Epic Seven", "value": "epic_seven", "image": "/imgs/categories/Epic Seven.png" },
    { "label": "Escape from Tarkov", "value": "escape_from_tarkov", "image": "/imgs/categories/Escape from Tarkov.png" },
    { "label": "Farlight 84", "value": "farlight_84", "image": "/imgs/categories/Farlight 84.png" },
    { "label": "FIFA", "value": "fifa", "image": "/imgs/categories/FIFA.png" },
    { "label": "For Honor", "value": "for_honor", "image": "/imgs/categories/For Honor.png" },
    { "label": "Fortnite", "value": "fortnite", "image": "/imgs/categories/Fortnite.png" },
    { "label": "Forza Horizon", "value": "forza_horizon", "image": "/imgs/categories/Forza Horizon.png" },
    { "label": "Free Fire", "value": "free_fire", "image": "/imgs/categories/Free Fire.png" },
    { "label": "Genshin Impact", "value": "genshin_impact", "image": "/imgs/categories/Genshin Impact.png" },
    { "label": "GOG", "value": "gog", "image": "/imgs/categories/GOG.png" },
    { "label": "Grand Chase", "value": "grand_chase", "image": "/imgs/categories/Grand Chase.png" },
    { "label": "Grand Fantasia", "value": "grand_fantasia", "image": "/imgs/categories/Grand Fantasia.png" },
    { "label": "GTA", "value": "gta", "image": "/imgs/categories/GTA.png" },
    { "label": "Guild Wars 2", "value": "guild_wars_2", "image": "/imgs/categories/Guild Wars 2.png" },
    { "label": "Habbo", "value": "habbo", "image": "/imgs/categories/Habbo.png" },
    { "label": "Hay Day", "value": "hay_day", "image": "/imgs/categories/Hay Day.png" },
    { "label": "Heartwood Online", "value": "heartwood_online", "image": "/imgs/categories/Heartwood Online.png" },
    { "label": "Honkai Impact", "value": "honkai_impact", "image": "/imgs/categories/Honkai Impact.png" },
    { "label": "Honkai: Star Rail", "value": "honkai_star_rail", "image": "/imgs/categories/Honkai Star Rail.png" },
    { "label": "Honor of Kings", "value": "honor_of_kings", "image": "/imgs/categories/Honor of Kings.png" },
    { "label": "Icarus Online", "value": "icarus_online", "image": "/imgs/categories/Icarus Online.png" },
    { "label": "IMVU", "value": "imvu", "image": "/imgs/categories/IMVU.png" },
    { "label": "Kakele Online", "value": "kakele_online", "image": "/imgs/categories/Kakele Online.png" },
    { "label": "League of Legends", "value": "league_of_legends", "image": "/imgs/categories/League of Legends.png" },
    { "label": "League of Legends: Wild Rift", "value": "league_of_legends_wild_rift", "image": "/imgs/categories/League of Legends Wild Rift.png" },
    { "label": "Legend Online", "value": "legend_online", "image": "/imgs/categories/Legend Online.png" },
    { "label": "Legends of Runeterra", "value": "legends_of_runeterra", "image": "/imgs/categories/Legends of Runeterra.png" },
    { "label": "Lords Mobile", "value": "lords_mobile", "image": "/imgs/categories/Lords Mobile.png" },
    { "label": "Lost Ark", "value": "lost_ark", "image": "/imgs/categories/Lost Ark.png" },
    { "label": "Magic The Gathering", "value": "magic_the_gathering", "image": "/imgs/categories/Magic The Gathering.png" },
    { "label": "Metin 2", "value": "metin_2", "image": "/imgs/categories/Metin 2.png" },
    { "label": "Minecraft", "value": "minecraft", "image": "/imgs/categories/Minecraft.png" },
    { "label": "MIR4", "value": "mir4", "image": "/imgs/categories/MIR4.png" },
    { "label": "Mobile Legends", "value": "mobile_legends", "image": "/imgs/categories/Mobile Legends.png" },
    { "label": "MU Legend", "value": "mu_legend", "image": "/imgs/categories/MU Legend.png" },
    { "label": "MU Online", "value": "mu_online", "image": "/imgs/categories/MU Online.png" },
    { "label": "My Hero Academia", "value": "my_hero_academia", "image": "/imgs/categories/My Hero Academia.png" },
    { "label": "Naruto Online", "value": "naruto_online", "image": "/imgs/categories/Naruto Online.png" },
    { "label": "New World", "value": "new_world", "image": "/imgs/categories/New World.png" },
    { "label": "Ni No Kuni", "value": "ni_no_kuni", "image": "/imgs/categories/Ni No Kuni.png" },
    { "label": "Noahs Heart", "value": "noahs_heart", "image": "/imgs/categories/Noahs Heart.png" },
    { "label": "Origin", "value": "origin", "image": "/imgs/categories/Origin.png" },
    { "label": "Overwatch", "value": "overwatch", "image": "/imgs/categories/Overwatch.png" },
    { "label": "Palworld", "value": "palworld", "image": "/imgs/categories/Palworld.png" },
    { "label": "Path of Exile", "value": "path_of_exile", "image": "/imgs/categories/Path of Exile.png" },
    { "label": "Perfect World", "value": "perfect_world", "image": "/imgs/categories/Perfect World.png" },
    { "label": "PES", "value": "pes", "image": "/imgs/categories/PES.png" },
    { "label": "Playstation", "value": "playstation", "image": "/imgs/categories/Playstation.png" },
    { "label": "Point Blank", "value": "point_blank", "image": "/imgs/categories/Point Blank.png" },
    { "label": "Pokemon GO", "value": "pokemon_go", "image": "/imgs/categories/Pokemon GO.png" },
    { "label": "Pokemon Unite", "value": "pokemon_unite", "image": "/imgs/categories/Pokemon Unite.png" },
    { "label": "PokeXGames", "value": "pokexgames", "image": "/imgs/categories/PokeXGames.png" },
    { "label": "Priston Tale", "value": "priston_tale", "image": "/imgs/categories/Priston Tale.png" },
    { "label": "PUBG", "value": "pubg", "image": "/imgs/categories/PUBG.png" },
    { "label": "Ragnarok", "value": "ragnarok", "image": "/imgs/categories/Ragnarok.png" },
    { "label": "Rainbow Six", "value": "rainbow_six", "image": "/imgs/categories/Rainbow Six.png" },
    { "label": "Ravendawn", "value": "ravendawn", "image": "/imgs/categories/Ravendawn.png" },
    { "label": "Red Dead Online", "value": "red_dead_online", "image": "/imgs/categories/Red Dead Online.png" },
    { "label": "Roblox", "value": "roblox", "image": "/imgs/categories/Roblox.png" },
    { "label": "Rocket League", "value": "rocket_league", "image": "/imgs/categories/Rocket League.png" },
    { "label": "Rucoy Online", "value": "rucoy_online", "image": "/imgs/categories/Rucoy Online.png" },
    { "label": "Runescape", "value": "runescape", "image": "/imgs/categories/Runescape.png" },
    { "label": "Rush Royale", "value": "rush_royale", "image": "/imgs/categories/Rush Royale.png" },
    { "label": "Saint Seiya Awakening", "value": "saint_seiya_awakening", "image": "/imgs/categories/Saint Seiya Awakening.png" },
    { "label": "Seafight", "value": "seafight", "image": "/imgs/categories/Seafight.png" },
    { "label": "Seven Deadly Sins", "value": "seven_deadly_sins", "image": "/imgs/categories/Seven Deadly Sins.png" },
    { "label": "Smite", "value": "smite", "image": "/imgs/categories/Smite.png" },
    { "label": "Standoff 2", "value": "standoff_2", "image": "/imgs/categories/Standoff 2.png" },
    { "label": "Steam", "value": "steam", "image": "/imgs/categories/Steam.png" },
    { "label": "Stumble Guys", "value": "stumble_guys", "image": "/imgs/categories/Stumble Guys.png" },
    { "label": "Subway Surfers", "value": "subway_surfers", "image": "/imgs/categories/Subway Surfers.png" },
    { "label": "Summoners War", "value": "summoners_war", "image": "/imgs/categories/Summoners War.png" },
    { "label": "Tibia", "value": "tibia", "image": "/imgs/categories/Tibia.png" },
    { "label": "Tower of Fantasy", "value": "tower_of_fantasy", "image": "/imgs/categories/Tower of Fantasy.png" },
    { "label": "Transformice", "value": "transformice", "image": "/imgs/categories/Transformice.png" },
    { "label": "Ubisoft", "value": "ubisoft", "image": "/imgs/categories/Ubisoft.png" },
    { "label": "Valorant", "value": "valorant", "image": "/imgs/categories/Valorant.png" },
    { "label": "Wakfu", "value": "wakfu", "image": "/imgs/categories/Wakfu.png" },
    { "label": "Warface", "value": "warface", "image": "/imgs/categories/Warface.png" },
    { "label": "Warframe", "value": "warframe", "image": "/imgs/categories/Warframe.png" },
    { "label": "Warspear", "value": "warspear", "image": "/imgs/categories/Warspear.png" },
    { "label": "With Your Destiny", "value": "with_your_destiny", "image": "/imgs/categories/With Your Destiny.png" },
    { "label": "World of Tanks", "value": "world_of_tanks", "image": "/imgs/categories/World of Tanks.png" },
    { "label": "World of Warcraft", "value": "world_of_warcraft", "image": "/imgs/categories/World of Warcraft.png" },
    { "label": "Xbox", "value": "xbox", "image": "/imgs/categories/Xbox.png" },
    { "label": "Outros Jogos", "value": "outros_jogos", "image": "/imgs/categories/Outros Jogos.png" },
    { "label": "Sugerir Jogo", "value": "sugerir_jogo", "image": "/imgs/bull/icone.png" }
]

export const others = [
    { "label": "Criptomoedas e NFT", "value": "criptomoedas_e_nft", "image": "https://th.bing.com/th/id/R.46afadddcc08be483a971581981f2b04?rik=5zZqzokQPvp5Ww&riu=http%3a%2f%2fstore-images.s-microsoft.com%2fimage%2fapps.17518.9007199266525906.711fe06b-31fe-48b3-85b2-9147c65d66ce.8e1c2c26-16e6-413b-926e-b785d38b915a&ehk=FJgCYuMHrIquhx9LbTCEvce5QbFloAQ8SsjBlH0T1ys%3d&risl=&pid=ImgRaw&r=0" },
    { "label": "Assinaturas e Premium", "value": "assinaturas_e_premium", "image": "https://vignette2.wikia.nocookie.net/logopedia/images/b/b2/NetflixIcon2016.jpg/revision/latest/scale-to-width-down/2000?cb=20160620223003" },
    { "label": "Emails", "value": "emails", "image": "https://th.bing.com/th/id/R.5325dd3e2e9e014060752a1dc40f7027?rik=anVZXWRbZbY6JA&pid=ImgRaw&r=0" },
    { "label": "Discord", "value": "discord", "image": "https://static.vecteezy.com/system/resources/previews/006/892/625/non_2x/discord-logo-icon-editorial-free-vector.jpg" },
    { "label": "Gift Cards", "value": "gift_cards", "image": "https://static.vecteezy.com/system/resources/previews/022/484/501/original/google-play-store-icon-logo-symbol-free-png.png" },
    { "label": "Redes Sociais", "value": "redes_sociais", "image": "https://th.bing.com/th/id/R.cc86a3353385063cc55b2675261f87d8?rik=yktDKaDmO08LXg&pid=ImgRaw&r=0" },
    // { "label": "Serviços Digitais", "value": "servicos_digitais", "image": "" },
    // { "label": "Softwares e Licenças", "value": "softwares_e_licencas", "image": "" },
    // { "label": "Apostas", "value": "apostas", "image": "https://th.bing.com/th/id/R.1766fe9dbd7d016d03bff30f8ffdd154?rik=1LD%2ftaV0C96w2g&pid=ImgRaw&r=0" },
    // { "label": "eBooks", "value": "ebooks", "image": "" },
    // { "label": "Doações", "value": "doacoes", "image": "" },
    // { "label": "Outros", "value": "outros", "image": "" }
]

export const levels = [
    { "id": 0, "label": "N/A", "value": "na" },
    { "id": 1, "label": "Bronze I", "value": "bronze_1" },
    { "id": 2, "label": "Bronze II", "value": "bronze_2" },
    { "id": 3, "label": "Bronze III", "value": "bronze_3" },
    { "id": 4, "label": "Silver I", "value": "silver_1" },
    { "id": 5, "label": "Silver II", "value": "silver_2" },
    { "id": 6, "label": "Silver III", "value": "silver_3" },
    { "id": 7, "label": "Gold I", "value": "gold_1" },
    { "id": 8, "label": "Gold II", "value": "gold_2" },
    { "id": 9, "label": "Gold III", "value": "gold_3" },
    { "id": 10, "label": "Cristal I", "value": "cristal_1" },
    { "id": 11, "label": "Cristal II", "value": "cristal_2" },
    { "id": 12, "label": "Cristal III", "value": "cristal_3" },
    { "id": 13, "label": "Master I", "value": "master_1" },
    { "id": 14, "label": "Master II", "value": "master_2" },
    { "id": 15, "label": "Master III", "value": "master_3" },
    { "id": 16, "label": "Champion I", "value": "champion_1" },
    { "id": 17, "label": "Champion II", "value": "champion_2" },
    { "id": 18, "label": "Champion III", "value": "champion_3" },
    { "id": 19, "label": "Titan I", "value": "titan_1" },
    { "id": 20, "label": "Titan II", "value": "titan_2" },
    { "id": 21, "label": "Titan III", "value": "titan_3" },
    { "id": 22, "label": "Legend", "value": "legend" },
]

export const coins = [
    {
        "label": "Real",
        "value": "BRL",
        "image": "https://img.icons8.com/color/48/brazil-circular.png"
    },
    {
        "label": "Dolar",
        "value": "USD",
        "image": "https://img.icons8.com/color/48/usa-circular.png"
    },
    {
        "label": "Euro",
        "value": "EUR",
        "image": "https://img.icons8.com/color/48/spain2-circular.png"
    },
    {
        "label": "Yen",
        "value": "JPY",
        "image": "https://img.icons8.com/color/48/japan-circular.png"
    },
    {
        "label": "Libra",
        "value": "GBP",
        "image": "https://img.icons8.com/color/48/great-britain-circular.png"
    },
    {
        "label": "Yuan",
        "value": "CNY",
        "image": "https://img.icons8.com/color/48/china-circular.png"
    },
    {
        "label": "Rublo",
        "value": "RUB",
        "image": "https://img.icons8.com/color/48/russian-federation-circular.png"
    },
    // {
    //     "label": "Bitcoin",
    //     "value": "BTC",
    //     "image": "https://th.bing.com/th/id/R.46afadddcc08be483a971581981f2b04?rik=5zZqzokQPvp5Ww&riu=http%3a%2f%2fstore-images.s-microsoft.com%2fimage%2fapps.17518.9007199266525906.711fe06b-31fe-48b3-85b2-9147c65d66ce.8e1c2c26-16e6-413b-926e-b785d38b915a&ehk=FJgCYuMHrIquhx9LbTCEvce5QbFloAQ8SsjBlH0T1ys%3d&risl=&pid=ImgRaw&r=0"
    // }
]

export const payments = [
    {
        "label": "Pix",
        "value": "PIX",
        "image": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC9UlEQVR4nO1a3U7UUBDuCxj1Wi/0SgT8SdRLRe+UznS98THUB1B8E2OC8aYzFRI6pxjfQMEb9RFQWQUimiiRRDP7RwMtS09PS9cwyUnIbpnzfbNz5sw3u553ZP+Z+fGLk2D4CQoto/BPEP6BhpdQaKY1N3fCa7L5Cd1Ew1/R8N/MJdzWZ7wmGgjdQaHfueB7CwxvYUwtbxTBYxNJQEHwjSIBluDTJED47kiCP1QS4Ag8HgYJ1+CxThJVgcc6SQTz88eCmG+A8FMwvF0auNAf9eUndF19e7XfvMJr9uB5DUw4VSnIIImCTs3e2XgdDc3eNtFpfX86Di/v2z7kpgttBEJX1Yf6AuHnXd876aR7uwa/A0BoBRZ5TJ/zF6NLBUms98HDIo+B0Ke8M2FN4iAHFoRWMaGJgiTW/Ti80g1QeE4D4fzGLlJtlIS/EE3q/6Ghi0NIFAKPNiRsSmWaxHT88jwa/pxxYNtKMAU+M21KkdCqkpfzBziUXwITjmeSEG63JLzQAW/CcX3Wbg/eytUTqqRsqsluEoMzsRBNoqH3ugYpltCELXhMByNL2XVkYBnHGSTS5gS86e0R8+OM3Od3Lpz3SGwGwg/xVXhWl/6tr7nyj4aX9hBQAe5wg0oXGNrMSCGnEap2CX/P+ARo2WWE0ET3W8ncGU0hSOiBywCB4bdZBGZG5hAbfrSHgJam7mXjBryWThD+CMIfXJZREFq99zo8vodA1RfZ4BYueZHBsPa720rwr6JR6Ud+n37Iqg9C+37oYCQKdqTVN3OF2ul0zhdop/taIKiqnS4qaCxU2W5Bs5IH3r0q62hgmvUXwlN98CD0zeLQb4CJrvUlpfpM62sn4IeSi/lWWVHv1z1u1xqspQyEnrkYq4Dh7a6vcCq3vo/SYAurnlhXOVrEusbtVQx3se7vClyO1/GwvuiwaTsaA74siUaAtyXRKPBFSTQS/K5xe3u/zrXycXpZU2WncxvVrfozg85PDYTeqAys/KY9Mq9++wfSId/ImvikTwAAAABJRU5ErkJggg=="
    },
    {
        "label": "Cartão de Crédito",
        "value": "PIX",
        "image": "https://cdn-icons-png.flaticon.com/512/217/217425.png"
    },
    {
        "label": "Boleto",
        "value": "BOLETO",
        "image": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA2ElEQVR4nO2YwQnDMAxFtZB36ApVT90mG2nEX9yDEcZqKXGh0PfAFysSMUnsp5gBAADAT3MNHXncQpcqnuf7dXNuFc81V3lVjWr+FB5SHnPxHJtvZs6t4rnmKq+qUc2z4E/gCQevtPiGjU1L7NLBsfSEczgQjwGmtQPHpUXz4HRLRnvo9MPiB0AH0wpMa4Bp7cAxLWFajmkZpuWYljCtDqYVmNYA09qBY1rCtBzTMkzLMS1hWh1MKzCtAaa1ifs02ot4pi1yq3h7k1fVqOZP8XcLBgAAsO/zAI5ZsZpNAB4lAAAAAElFTkSuQmCC"
    },
    {
        "label": "Binance Pay",
        "value": "BINANCEPAY",
        "image": "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 126.61 126.61'%3E%3Cg fill='%23f3ba2f'%3E%3Cpath d='m38.73 53.2 24.59-24.58 24.6 24.6 14.3-14.31-38.9-38.91-38.9 38.9z'/%3E%3Cpath d='m0 63.31 14.3-14.31 14.31 14.31-14.31 14.3z'/%3E%3Cpath d='m38.73 73.41 24.59 24.59 24.6-24.6 14.31 14.29-38.9 38.91-38.91-38.88z'/%3E%3Cpath d='m98 63.31 14.3-14.31 14.31 14.3-14.31 14.32z'/%3E%3Cpath d='m77.83 63.3-14.51-14.52-10.73 10.73-1.24 1.23-2.54 2.54 14.51 14.5 14.51-14.47z'/%3E%3C/g%3E%3C/svg%3E"
    },
    {
        "label": "Bitcoin",
        "value": "BTC",
        "image": "data:image/svg+xml,%3Csvg xmlns:rdf='http://www.w3.org/1999/02/22-rdf-syntax-ns%23' xmlns='http://www.w3.org/2000/svg' height='64' width='64' version='1.1' xmlns:cc='http://creativecommons.org/ns%23' xmlns:dc='http://purl.org/dc/elements/1.1/'%3E%3Cg transform='translate(0.00630876,-0.00301984)'%3E%3Cpath fill='%23f7931a' d='m63.033,39.744c-4.274,17.143-21.637,27.576-38.782,23.301-17.138-4.274-27.571-21.638-23.295-38.78,4.272-17.145,21.635-27.579,38.775-23.305,17.144,4.274,27.576,21.64,23.302,39.784z'/%3E%3Cpath fill='%23FFF' d='m46.103,27.444c0.637-4.258-2.605-6.547-7.038-8.074l1.438-5.768-3.511-0.875-1.4,5.616c-0.923-0.23-1.871-0.447-2.813-0.662l1.41-5.653-3.509-0.875-1.439,5.766c-0.764-0.174-1.514-0.346-2.242-0.527l0.004-0.018-4.842-1.209-0.934,3.75s2.605,0.597,2.55,0.634c1.422,0.355,1.679,1.296,1.636,2.042l-1.638,6.571c0.098,0.025,0.225,0.061,0.365,0.117-0.117-0.029-0.242-0.061-0.371-0.092l-2.296,9.205c-0.174,0.432-0.615,1.08-1.609,0.834,0.035,0.051-2.552-0.637-2.552-0.637l-1.743,4.019,4.569,1.139c0.85,0.213,1.683,0.436,2.503,0.646l-1.453,5.834,3.507,0.875,1.439-5.772c0.958,0.26,1.888,0.5,2.798,0.726l-1.434,5.745,3.511,0.875,1.453-5.823c5.987,1.133,10.489,0.676,12.384-4.739,1.527-4.36-0.076-6.875-3.226-8.515,2.294-0.529,4.022-2.038,4.483-5.155zm-8.022,11.249c-1.085,4.36-8.426,2.003-10.806,1.412l1.928-7.729c2.38,0.594,10.012,1.77,8.878,6.317zm1.086-11.312c-0.99,3.966-7.1,1.951-9.082,1.457l1.748-7.01c1.982,0.494,8.365,1.416,7.334,5.553z'/%3E%3C/g%3E%3C/svg%3E"
    },
    {
        "label": "Ethereum",
        "value": "ETH",
        "image": "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2500' height='2500' viewBox='0 0 32 32'%3E%3Cscript xmlns='' id='custom-useragent-string-page-script'/%3E%3Cg fill='none' fillRule='evenodd'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23627EEA'/%3E%3Cg fill='%23FFF' fillRule='nonzero'%3E%3Cpath fill-opacity='.602' d='M16.498 4v8.87l7.497 3.35z'/%3E%3Cpath d='M16.498 4L9 16.22l7.498-3.35z'/%3E%3Cpath fill-opacity='.602' d='M16.498 21.968v6.027L24 17.616z'/%3E%3Cpath d='M16.498 27.995v-6.028L9 17.616z'/%3E%3Cpath fill-opacity='.2' d='M16.498 20.573l7.497-4.353-7.497-3.348z'/%3E%3Cpath fill-opacity='.602' d='M9 16.22l7.498 4.353v-7.701z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
    },
    {
        "label": "Tether",
        "value": "USDT",
        "image": "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2000 2000' width='2000' height='2000'%3E%3Cpath d='M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0' fill='%2353ae94'/%3E%3Cpath d='M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18' fill='%23fff'/%3E%3C/svg%3E"
    },

]

export const optionsProfile = [
    { title: "Resumo", icon: "M4.5 3.75a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V6.75a3 3 0 0 0-3-3h-15Zm4.125 3a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.873 8.703a4.126 4.126 0 0 1 7.746 0 .75.75 0 0 1-.351.92 7.47 7.47 0 0 1-3.522.877 7.47 7.47 0 0 1-3.522-.877.75.75 0 0 1-.351-.92ZM15 8.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15ZM14.25 12a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H15a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15Z" },
    { title: "Compras", icon: "M2.273 5.625A4.483 4.483 0 0 1 5.25 4.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 3H5.25a3 3 0 0 0-2.977 2.625ZM2.273 8.625A4.483 4.483 0 0 1 5.25 7.5h13.5c1.141 0 2.183.425 2.977 1.125A3 3 0 0 0 18.75 6H5.25a3 3 0 0 0-2.977 2.625ZM5.25 9a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h13.5a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3H15a.75.75 0 0 0-.75.75 2.25 2.25 0 0 1-4.5 0A.75.75 0 0 0 9 9H5.25Z" },
    { title: "Favoritos", icon: "M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" },
    { title: "Configurações", icon: "M11.828 2.25c-.916 0-1.699.663-1.85 1.567l-.091.549a.798.798 0 0 1-.517.608 7.45 7.45 0 0 0-.478.198.798.798 0 0 1-.796-.064l-.453-.324a1.875 1.875 0 0 0-2.416.2l-.243.243a1.875 1.875 0 0 0-.2 2.416l.324.453a.798.798 0 0 1 .064.796 7.448 7.448 0 0 0-.198.478.798.798 0 0 1-.608.517l-.55.092a1.875 1.875 0 0 0-1.566 1.849v.344c0 .916.663 1.699 1.567 1.85l.549.091c.281.047.508.25.608.517.06.162.127.321.198.478a.798.798 0 0 1-.064.796l-.324.453a1.875 1.875 0 0 0 .2 2.416l.243.243c.648.648 1.67.733 2.416.2l.453-.324a.798.798 0 0 1 .796-.064c.157.071.316.137.478.198.267.1.47.327.517.608l.092.55c.15.903.932 1.566 1.849 1.566h.344c.916 0 1.699-.663 1.85-1.567l.091-.549a.798.798 0 0 1 .517-.608 7.52 7.52 0 0 0 .478-.198.798.798 0 0 1 .796.064l.453.324a1.875 1.875 0 0 0 2.416-.2l.243-.243c.648-.648.733-1.67.2-2.416l-.324-.453a.798.798 0 0 1-.064-.796c.071-.157.137-.316.198-.478.1-.267.327-.47.608-.517l.55-.091a1.875 1.875 0 0 0 1.566-1.85v-.344c0-.916-.663-1.699-1.567-1.85l-.549-.091a.798.798 0 0 1-.608-.517 7.507 7.507 0 0 0-.198-.478.798.798 0 0 1 .064-.796l.324-.453a1.875 1.875 0 0 0-.2-2.416l-.243-.243a1.875 1.875 0 0 0-2.416-.2l-.453.324a.798.798 0 0 1-.796.064 7.462 7.462 0 0 0-.478-.198.798.798 0 0 1-.517-.608l-.091-.55a1.875 1.875 0 0 0-1.85-1.566h-.344ZM12 15.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" },
    { title: "Verificações", icon: "M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" },
    { title: "Segurança", icon: "M12.516 2.17a.75.75 0 0 0-1.032 0 11.209 11.209 0 0 1-7.877 3.08.75.75 0 0 0-.722.515A12.74 12.74 0 0 0 2.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 0 0 .374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 0 0-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08Zm3.094 8.016a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" },
    { title: "Sair", icon: "M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-4.28 9.22a.75.75 0 0 0 0 1.06l3 3a.75.75 0 1 0 1.06-1.06l-1.72-1.72h5.69a.75.75 0 0 0 0-1.5h-5.69l1.72-1.72a.75.75 0 0 0-1.06-1.06l-3 3Z" },
]